<template>
  <div class="leftMunu">
    <div class="top">
      <div class="imgBox">
        <img src="@/assets/images/home/logo.png" alt="" />
      </div>
      <ul>
        <li class="itemLI">
          <span class="iconfont font_size">&#xe60d;</span>
          <span class="text">{{ $t("home.home") }}</span>
        </li>
        <li class="itemLI">
          <span class="iconfont font_size">&#xe6e1;</span>
          <span class="text">{{ $t("home.company") }}</span>
        </li>
        <li class="itemLI">
          <span class="iconfont font_size">&#xe693;</span>
          <span class="text">{{ $t("home.product") }}</span>
        </li>
        <li class="itemLI">
          <span class="iconfont font_size">&#xe641;</span>
          <span class="text">{{ $t("home.superiority") }}</span>
        </li>
        <li class="itemLI">
          <span class="iconfont font_size">&#xe8a0;</span>
          <span class="text">{{ $t("home.contactUs") }}</span>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <div class="left">
        <span>{{ $t("home.Change_language") }}</span
        ><van-switch v-model="checked" />
      </div>
      <div class="right">
        <span>{{ $t("home.backManagement") }}</span
        ><span class="iconfont font_size">&#xe722;</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkMLeftMenu",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {},
  created() {},
  methods: {},
  watch: {
    checked: function (val) {
      var lang = localStorage.getItem("lang") || "en";
      if (lang == "en" && val) {
        lang = "zh";
      } else {
        lang = "en";
      }
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="less" scoped>
.leftMunu {
  position: relative;
  width: 540px;
  height: 1334px;
  //   background: pink;
  .top {
    margin-left: 52px;
    padding-top: 52px;
    .imgBox {
      width: 126px;
      height: 42px;
      margin-bottom: 72px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .itemLI {
      margin-bottom: 66px;
      .font_size {
        font-size: 40px;
      }
      .text {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-left: 32px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 33px;
    bottom: 42px;
    right: 33px;
    font-size: 23px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    .left {
      display: flex;
      align-items: center;
      .van-switch {
        width: 82px;
        height: 42px;
        background-color: #d8d5d8;
        /deep/ .van-switch__node {
          width: 42px;
          height: 42px;
        }
      }
      .van-switch--on {
        background-color: #1989fa;
        /deep/.van-switch__node {
          transform: translateX(37px);
        }
      }
    }
  }
}
</style>