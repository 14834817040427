<template>
  <div id="app">
    <van-popup v-model="show" position="left" class="left">
      <LeftMenu v-if="show"></LeftMenu>
    </van-popup>
    <transition :name="transitionName">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view class="router-view"></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        class="router-view"
      ></router-view>
    </transition>

    <!-- <router-view /> -->
  </div>
</template>
 <script>
import LeftMenu from "@/components/leftMenu.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: { LeftMenu },
  created() {
    // if (navigator.language == "zh-Cn") {
    //   localStorage.setItem("lang", "zh");
    // } else {
    //   localStorage.setItem("lang", "en");
    // }
    localStorage.setItem("lang", "en");
    // 控制网站跳转
    this.goOrther();
  },

  data() {
    return {
      show: false,
      transitionName: "",
    };
  },
  computed: {
    ...mapState(["LeftMenu", "isIosMoveBack"]),
  },
  mounted() {
    // getDeviceType函数为判断机型（IOS时返回true)
    if (this.getDeviceType()) {
      document.body.addEventListener(
        "touchmove",
        () => {
          this.$store.commit("setIsIosMoveBack", true);
        },
        false
      );
    }
  },
  methods: {
    ...mapMutations(["updataLeftMenu"]),
    getDeviceType() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        return true;
      } else {
        return false;
      }
    },
    goOrther() {
      var is_mobi =
        navigator.userAgent
          .toLowerCase()
          .match(
            /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
          ) != null;
      if (is_mobi) {
        // window.location.href = "mobile.html";
      } else {
        let params = new URL(window.location.href).hash;
        // let baseurl = "http://localhost:8081/" + params;  // 测试用的一定要先打开移动端再打开pc端
        let baseurl = "http://www.td-button.com/" + params; // 线上接口
        window.location.href = baseurl;
      }
    },
  },
  watch: {
    LeftMenu: {
      handler: function (val, oldVal) {
        this.show = val;
      },
      deep: true,
    },
    show: {
      handler: function (val, oldVal) {
        if (!val) {
          this.updataLeftMenu(false);
        }
      },
      deep: true,
    },
    //使用watch 监听$router的变化
    $route(to, from) {
      if (this.isIosMoveBack) {
        this.transitionName = "";
        this.$store.commit("setIsIosMoveBack", false);
      } else {
        if (to.meta.title == "产品" && from.meta.title == "产品详情") {
          this.transitionName = "slide-right";
        } else {
          this.transitionName = "slide-left";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  .left {
    height: 100%;
  }
}
.router-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
