<template>
  <div class="top">
    <span class="iconfont size" @click="showPopup">&#xe62b;</span>
    <div class="text">
      <img v-show="imgshow" src="@/assets/images/home/logo.png" alt="" />
      {{ $t(title) }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "CompanynetworkMTopMenuText",

  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    imgshow: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {},

  methods: {
    ...mapMutations(["updataLeftMenu"]),
    showPopup() {
      this.updataLeftMenu(true);
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  width: 750px;
  height: 85px;
  text-align: center;
  line-height: 85px;
  position: relative;
  background-color: #fff;
  .size {
    position: absolute;
    left: 36px;
    top: 0;
    font-size: 42px;
  }
  .text {
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    img {
      width: 126px;
      height: 42px;
      vertical-align: middle;
      margin-right: 24px;
    }
  }
}
</style>