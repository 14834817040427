// en.js
module.exports = {
  language: {
    name: "English",
    current: "Current Language",
  },
  // 首页
  home: {
    home: "Home",
    company: "Company",
    product: "Product",
    superiority: "Advantages",
    contactUs: "Contact us",
    backManagement: " Enter the management background",
    Change_language: "切换为中文",
  },
  // 公司介绍
  company: {
    company: "Company",
    text: `Jiashan tianluda industry and Trade Co., Ltd. specializes in manufacturing buttons, ribbons, clothing accessories, luggage accessories, handicrafts, etc. it is a member unit of the national button Standardization Technical Committee, a director unit of China daily necessities Industry Association, a member unit of China Industrial Design Association, a vice president unit of Zhejiang creative design Association, a president unit of Jiaxing Creative Design Association, and an executive vice president unit of Jiaxing Industrial Design Association, Jiaxing civil construction entrepreneur association executive vice president unit, Jiashan County Arts and Crafts Industry Association vice president unit, Jiashan County E-Commerce Association vice president unit, Jiashan County button chamber of Commerce executive vice president unit. The intellectual property rights include: 5 authorized invention patents, 21 practical and novel patents, and 132 design patents. The company has passed ISO9001 quality management system certification, ISO14001 environmental management system certification, iso45001 occupational health and safety management system certification, standard 100 by Oeko-Tex international certification and product standard Zhejiang manufacturing certification. The company won the titles of "national high-tech enterprise", "Zhejiang innovative demonstration enterprise", "Jiaxing honest and law-abiding enterprise", "Jiaxing honest private enterprise", "Jiaxing fine management demonstration enterprise", "Jiaxing patent demonstration enterprise", "Jiaxing growth star enterprise", "Jiashan civilized unit", and was awarded the title of "Jiaxing Municipal Industrial Design Center", "county-level button technology research and development center" "Jiashan County High Skilled Talents Innovation Studio" and so on.`,
    Company_glory: "Company glory",
    qualification_certificate: "Qualification certificate",
  },
  // 产品数据
  product: {
    product: "Product",
    screen: "Filter",
    Img_search: "Img Search",
    category: "Category",
    material_quality: "Material",
    function: "Function",
    resetting: "Reset",
    make_sure: "Confirm",
    Reset_all_screens: "Reset all screens",
    Figure_search_search: "Image search",
    Re_box_selection: "Re-box selection",
    Change_a: "Change a",
    Cancel_the_map_search: "Cancel the search",
    No_data: "No data",
    Box_pictures: "Box to choose pictures",
    Garment_Accessories: "Garment Accessories",
  },
  // 优势与服务
  superiority: {
    enterprise_advantage: "Enterprise advantage",
    Our_service: "Our service",
    first: "Multi-category one-stop auxiliary materials efficient supply",
    second: "Industry-leading product development and quality management level",
    third:
      "Customer demand-oriented, an efficient way to solve customer problems, and grow together with customers",
    fourth:
      "Introduce the most advanced technology to provide convenient services for customers",
    Our_first: "1. Exclusive service personnel",
    Our_second: "2. Digital order tracking system",
    Our_third: "3. Perfect cloud on the exhibition hall",
    Our_fourth:
      "4. Global logistics customs declaration and settlement services",
    Our_fifth: "5. Cooperative innovation and research and development",
  },
  // 联系我们
  contact: {
    contact_us: "Contact us",
    contact_number: "Contact number",
    mail_box: "Mail box",
    Factory_address: "Factory address",
    Factory_address1:
      "#76-80 Shangwang Rd. Dashun Pioneer Park, Jiashan, Zhejiang",
    Company_address: "Company address",
    Company_address1: "T2-1302 EFC, 1118 Xiangwang Rd. Hangzhou, Zhejiang",
    Wechat: "Wechat",
  },
};
