import Vue from "vue";
ImagePreview;
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import animated from "animate.css"; // npm install animate.css --save安装，在引入
import "babel-polyfill";
import "es6-promise/auto";
import "amfe-flexible";
import FastClick from "fastclick";
import "@/assets/font/iconfont.css";
import Vant from "vant";
import { Lazyload } from "vant";
import { Locale, Sticky, ImagePreview } from "vant";
import AOS from "aos";
import "aos/dist/aos.css";
Vue.use(AOS);
AOS.init({
  once: true,
});

Vue.use(ImagePreview);
import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";
import enMy from "@/assets/i18n/en.js";
import z_chMy from "@/assets/i18n/zh.js";
Vue.use(Lazyload);
Vue.use(Sticky);
import "vant/lib/index.css";
Vue.use(Vant);
FastClick.attach(document.body);
Vue.use(animated);
Vue.use(VueI18n);
const messages = {
  zh: { ...zhCN, ...z_chMy },
  // z_chMy,
  en: {
    ...enUS,
    ...enMy,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en",
  messages: messages,
});

export const getLocale = () => {
  if (localStorage.getItem("lang") == "en") {
    return ["en-US", "en"];
  } else {
    return ["zh-CN", "zh"];
  }
};
const CURRENT_LANG = getLocale();
Locale.use(CURRENT_LANG[0], messages[CURRENT_LANG[1]]);
Vue.config.productionTip = false;
import "@/assets/css/rest.css";
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
