import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/home.vue";
Vue.use(VueRouter);
const routes = [
  { path: "/", redirect: "company" },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "首页",
      titleEn: "home",
      keepAlive: false,
      indexs: "1",
    },
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    meta: { title: "产品", titleEn: "Product", keepAlive: true, indexs: "1" }, // true 表示需要使用缓存},
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/product/product.vue"),
  },
  {
    path: "/product/detail/:uid?",
    name: "ProductDetail",
    meta: {
      title: "产品详情",
      titleEn: "Product Details",
      keepAlive: true,
      indexs: "2",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/detaile.vue"),
  },
  {
    path: "/superiority",
    name: "Superiority",
    meta: {
      title: "优势与服务",
      titleEn: "Advantages ",
      keepAlive: false,
      indexs: "1",
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/superiority/superiority.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "联系我们",
      titleEn: "Contact us",
      keepAlive: false,
      indexs: "1",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/contact/contact.vue"),
  },
  {
    path: "/company",
    name: "Company",
    meta: {
      title: "公司介绍",
      titleEn: "Company ",
      keepAlive: false,
      indexs: "1",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/company/company.vue"),
  },

  // { path: "*", redirect: "/home" },
];
// const scrollBehavior = (to, from, savedPosition) => {
//   return { x: 0, y: 0 };
// };

const router = new VueRouter({
  linkActiveClass: "active",
  // scrollBehavior,
  routes,
});
// 路由的前置守卫
router.beforeEach((to, from, next) => {
  // to 要去哪个路由
  // form 从哪个路由来
  // 如何控制路由的跳转与取消跳转  跳转 next()  不跳转next（false） 永远不能跳转了  next() 可以重新定向到某个页面
  if (localStorage.getItem("lang") == "zh") {
    document.title = to.meta.title;
  } else if (localStorage.getItem("lang") == "en") {
    document.title = to.meta.titleEn;
  }
  next();
});

router.afterEach((to, from) => {
  // setTimeout(function () {
  //   // alert("Please enterprise");
  //   // window.scrollTo(0, 0);
  // }, 1000);
});

export default router;
