<template>
  <div class="home">
    <van-sticky :offset-top="0">
      <TopMenuText title="home.home"></TopMenuText>
    </van-sticky>
    <div style="text-align: center">{{ $t("home.home") }}</div>

    <!-- <van-cell @click="showPopup">{{ $t("home.backManagement") }}</van-cell> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LeftMenu from "./components/leftMenu.vue";
import TopMenuText from "@/components/topMenuText";
export default {
  name: "CompanynetworkMHome",
  components: {
    LeftMenu,
    TopMenuText,
  },
  data() {
    return {};
  },
  mounted() {},
  creted() {
    this.updataLeftMenu(false);
  },
  updated() {},

  methods: {
    ...mapMutations(["updataLeftMenu"]),
    showPopup() {
      this.updataLeftMenu(true);
    },
  },
};
</script>

<style lang="less" scoped>
</style>