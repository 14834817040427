<template>
  <div class="leftMunu">
    <div class="top">
      <div class="imgBox">
        <img src="@/assets/images/home/logo.png" alt="" />
      </div>
      <ul>
        <!-- 展示隐藏的路由首页 -->
        <!-- <li class="itemLI">
          <router-link to="/home" @click.native="updataLeftMenu(false)"
            ><span class="iconfont font_size">&#xe60d;</span>
            <span class="text">{{ $t("home.home") }}</span>
          </router-link>
        </li> -->
        <li class="itemLI">
          <router-link to="/company" @click.native="updataLeftMenu(false)">
            <span class="iconfont font_size">&#xe6e1;</span>
            <span class="text">{{ $t("home.company") }}</span>
          </router-link>
        </li>
        <li class="itemLI">
          <router-link to="/product" @click.native="updataLeftMenu(false)">
            <span class="iconfont font_size">&#xe693;</span>
            <span class="text">{{ $t("home.product") }}</span></router-link
          >
        </li>
        <li class="itemLI">
          <router-link to="/superiority" @click.native="updataLeftMenu(false)">
            <span class="iconfont font_size">&#xe641;</span>
            <span class="text">{{ $t("home.superiority") }}</span></router-link
          >
        </li>
        <li class="itemLI">
          <router-link to="/contact" @click.native="updataLeftMenu(false)">
            <span class="iconfont font_size">&#xe8a0;</span>
            <span class="text">{{ $t("home.contactUs") }}</span></router-link
          >
        </li>
      </ul>
    </div>
    <div class="bottom">
      <div class="left">
        <span>{{ $t("home.Change_language") }}</span>
        <van-switch v-model="checked" />
      </div>
      <!-- <div class="right" @click="otherUrl">
        <span>{{ $t("home.backManagement") }}</span
        ><span class="iconfont font_size">&#xe722;</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { Locale } from "vant";
export default {
  name: "CompanynetworkMLeftMenu",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {},
  created() {
    // var lang = localStorage.getItem("lang") || "zh";
  },
  computed: {
    ...mapState(["LeftMenu"]),
  },
  methods: {
    ...mapMutations(["updataLeftMenu", "updataLanguage"]),
    otherUrl() {
      window.open("https://iscm.cnfzflw.com", "_blank");
    },
  },
  beforeUpdate() {
    if (localStorage.getItem("lang") == "zh") {
      document.title = this.$route.meta.title;
    } else if (localStorage.getItem("lang") == "en") {
      document.title = this.$route.meta.titleEn;
    }
  },
  watch: {
    checked: function (val) {
      var lang = localStorage.getItem("lang") || "zh";
      let vant = "";
      if (lang == "en" && val) {
        lang = "zh";
        vant = "zh-CN";
      } else {
        lang = "en";
        vant = "en-US";
      }
      this.updataLanguage(lang);
      // localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      Locale.use(vant, this.$i18n.messages[lang]);
    },
  },
};
</script>

<style lang="less" scoped>
.leftMunu {
  position: relative;
  width: 540px;
  height: 100%;
  //   background: pink;
  .top {
    margin-left: 52px;
    padding-top: 52px;
    .imgBox {
      width: 126px;
      height: 42px;
      margin-bottom: 72px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .itemLI {
      margin-bottom: 66px;
      color: #000000;
      a {
        color: #000000;
      }
      .active {
        color: #2e82ff;
      }
      .font_size {
        font-size: 40px;
      }
      .text {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // color: #000000;
        margin-left: 32px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 33px;
    bottom: 42px;
    right: 33px;
    font-size: 23px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    .left {
      display: flex;
      align-items: center;
      .van-switch {
        width: 82px;
        height: 42px;
        margin-left: 20px;
        background-color: #d8d5d8;
        /deep/ .van-switch__node {
          width: 42px;
          height: 42px;
        }
      }
      .van-switch--on {
        background-color: #1989fa;
        /deep/.van-switch__node {
          transform: translateX(40px);
        }
      }
    }
  }
}
</style>