import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    LeftMenu: localStorage.getItem("LeftMenu"),
    language: localStorage.getItem("lang") || "en",
    isIosMoveBack: false,
  },
  mutations: {
    updataLeftMenu(state, data) {
      state.LeftMenu = data;
    },
    updataLanguage(state, lang) {
      localStorage.setItem("lang", lang);
      state.language = lang;
    },
    setIsIosMoveBack(state, param) {
      state.isIosMoveBack = param;
    },
  },
  actions: {},
  modules: {},
});
