// zh.js
module.exports = {
  language: {
    name: "中文",
    current: "当前语言",
  },
  // home页数据
  home: {
    home: "首页",
    company: "公司介绍",
    product: "产品",
    superiority: "优势与服务",
    contactUs: "联系我们",
    backManagement: "进入管理后台",
    Change_language: "Switch to English",
  },
  // 公司介绍
  company: {
    company: "公司介绍",
    text: "嘉善天路达工贸有限公司专业制造钮扣、织带、服装辅料、箱包配件、工艺品等，是全国钮扣标准化技术委员会委员单位，中国日用杂品工业协会理事单位，中国工业设计协会会员单位，浙江省创意设计协会副会长单位，嘉兴市创意设计协会会长单位，嘉兴市工业设计协会常务副会长单位，嘉兴市民建企业家协会常务副会长单位，嘉善县工艺美术行业协会副会长单位，嘉善县电子商务协会副会长单位，嘉善县钮扣商会执行副会长单位。知识产权有：已授权发明专利5件，实用新颖专利21件,外观设计专利132件。公司已通过ISO9001质量管理体系认证、ISO14001 环境管理体系认证、ISO45001职业健康安全管理体系认证、STANDARD 100 by OEKO-TEX国际认证和品字标浙江制造认证。公司荣获：“国家高新技术企业”、“浙江省创新型示范企业”、“嘉兴市诚信守法企业”、“嘉兴市诚信民营企业”、“嘉兴市精细化管理示范企业”、“嘉兴市专利示范企业”、“嘉兴市成长之星企业”、“嘉善县文明单位”，被授予“嘉兴市级工业设计中心”、“县级钮扣技术研发中心”、“嘉善县高技能人才创新工作室”等。",
    Company_glory: "公司荣耀",
    qualification_certificate: "资质证书",
  },
  // 产品数据
  product: {
    product: "产品",
    screen: "筛选",
    Img_search: "图搜搜",
    category: "品类",
    material_quality: "材质",
    function: "功能",
    resetting: "重置",
    make_sure: "确定",
    Figure_search_search: "图搜搜",
    Re_box_selection: "重新框选",
    Change_a: "换一张",
    Cancel_the_map_search: "取消图搜",
    No_data: "暂无数据",
    Box_pictures: "框选图片",
    Garment_Accessories: "服装辅料",
  },
  // 优势与服务
  superiority: {
    enterprise_advantage: "企业优势",
    Our_service: "我们的服务",
    first: "多品类一站式的辅料高效供给",
    second: "行业领先的产品开发及品质管理水平",
    third: "客户需求导向，高效解决客户问题，与客户共同成长",
    fourth: "引进最先进技术为客户提供便捷服务",
    Our_first: "1. 专属服务人员",
    Our_second: "2. 数字化订单 跟踪系统",
    Our_third: "3. 完善的云上展厅",
    Our_fourth: "4. 全球物流报关及结算服务",
    Our_fifth: "5. 合作创新研发",
  },
  // 联系我们页面数据
  contact: {
    contact_us: "联系我们",
    contact_number: "联系电话",
    mail_box: "电子邮箱",
    Factory_address: "工厂地址",
    Factory_address1: "浙江省嘉善县大舜服装辅料创业园上旺路76-80号",
    Company_address: "公司地址",
    Company_address1: "浙江省杭州市余杭区欧美 金融城英国中心T2-1302",
    Wechat: "微信",
  },
};
